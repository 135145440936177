import type { Database } from "@asap/shared";

type Table = keyof Database["public"]["Tables"];
type Enum = keyof Database["public"]["Enums"];
type CompositeType = keyof Database["public"]["CompositeTypes"];
type Column = {
  [K in Table]: keyof Database["public"]["Tables"][K]["Row"];
}[Table];

const mapTableEmoji: Partial<Record<Table, string>> = {
  agency: "📌",
  mission: "🔮",
  talent: "👨‍🏭",
  company: "🏭",
  job: "🔨",
  business_unit: "🧑‍🤝‍🧑",
  specialty: "⭐️",
  team_member: "👨‍💼",
  contact: "🐶",
  contract: "⚖️",
  timesheet_week: "📅",
  professional_card_request: "🪪",
  hiring_pool: "🎯",
  project_typology: "📐",
  workspace: "👅",
  region: "📍",
  department: "📍",
} as const;

const mapEnumEmoji: Partial<Record<Enum, string>> = {
  mission_status: "🔢",
  job_posting_status: "📡",
  talent_qualification_status: "🔖",
  talent_availability_status: "🟢",
  contract_type: "📝",
  construction_type: "🚧",
  experience_level: "🪴",
  team_size: "🍄",
  construction_site_size: "💶",
} as const;

const mapCompositeTypesEmoji: Partial<Record<CompositeType, string>> = {
  address: "📍",
} as const;

const mapColumnEmoji: Partial<Record<Column, string>> = {
  is_top_profile: "⭐️",
  has_managerial_experience: "👷‍♂️",
  has_driver_license: "🪪",
  has_vehicle: "🚗",
  is_opened_to_business_trips: "🛣️",
};

export const mapEmoji = {
  ...mapTableEmoji,
  ...mapEnumEmoji,
  ...mapCompositeTypesEmoji,
  ...mapColumnEmoji,
} as const;
